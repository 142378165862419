import React from "react";
import "./Instruction.css";

function Instruction() {
  return (
    <>
      <div className="main-ins">
        <div className="container-ins">
          <div className="logo-ins">
            <div className="">
              <h1 className="">X Power</h1>
              <p>Advanced Fully Synthetic Motor Oil</p>
            </div>
            <div className="logo-ins-img">
              <img
                src="https://www.tirrentglobal.com/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                width="70%"
              />
            </div>
          </div>
          <div className="" style={{display:"flex", justifyContent:"center"}}>
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/x-power.png"
              width="50%"
            />
          </div>

          <div className="content-ins">
            <div className="">
              <h3>
                <span style={{ color: "#f99f36" }}>X POWER GOLD SERIES</span> -
                ADVANCED MOTOR OIL WITH MICROLUBRICANT TECHNOLOGY
              </h3>
              <div className="gold-series-ins">
                <h4>X POWER GOLD SERIES Motor Oil</h4>
                <p className="para-ins">
                  Sets a new standard in lubrication with advanced
                  Micro-Lubricant Technology, surpassing conventional motor
                  oils. Formulated with virgin base oil and premium imported
                  additives, it enhances engine performance, efficiency, and
                  reliability. Experience next-generation lubrication where
                  innovation meets excellence.
                </p>
                <p className="para-ins">
                  X POWER Micro-Lubricating Technology ensures your Engine
                  performs at its best. Experience unmatched benefits that
                  enhance every aspect of your vehicle's operation. With our
                  innovative technology, your will enjoy unstoppable performance
                  on every journey.
                </p>
              </div>
            </div>
            <div className="advangate-ins">
              <h3 style={{ color: "#f99f36" }}>
                Advantage of X POWER GOLD Series
              </h3>
              <ul>
                <li>
                  <h4>Maximize Engines Potential:</h4>
                  <p>
                    In a vehicle engine output plays a vital role in overall
                    performance. Our advanced anti-friction formula reduces
                    internal engine resistance by up to 95%, resulting in
                    significantly enhanced power delivery. This reduction in
                    friction leads to improved efficiency, making it easier to
                    accelerate and pull away with greater ease than ever before.
                    With our technology, you can experience up to 15% more power
                    output, ensuring smoother, more powerful performance that
                    helps your vehicle operate at its peak, every time.
                  </p>
                </li>
                <li>
                  <h4>
                    Quieter Engine, Smoother Operation, and Enhanced Comfort:
                  </h4>
                  <p>
                    With the reduction in friction, your engine will run quieter
                    than ever before, significantly lowering engine noise and
                    vibration. This not only results in a more comfortable
                    driving experience for the operator but also helps extend
                    the lifespan of rubber components like engine mountings and
                    flexible hoses, which are less affected by vibrations.
                    Moreover, the smooth operation enhances overall driving
                    comfort, making each journey more pleasant. Experience a
                    level of smoothness and quietness you’ve never felt before,
                    elevating both performance and comfort
                  </p>
                </li>
                <li>
                  <h4>
                    Unmatched Thermal Stability and Extended Oil Drain
                    Intervals:
                  </h4>
                  <p>
                    Thanks to X POWER GOLD SERIES high thermal stability, you
                    can confidently operate your vehicle in all weather and load
                    conditions without worrying about oil degradation. The
                    reduction in friction, along with the ability to hold high
                    compression and effectively clean internal engine
                    components, also offers the significant benefit of extended
                    oil drain intervals.
                  </p>
                  <p>
                    For continuous users of X POWER GOLD SERIES motor oil , you
                    can double* the typical oil drain period without
                    compromising engine performance. Enjoy longer-lasting,
                    efficient operation with the peace of mind that your engine
                    is running smoothly and effectively
                  </p>
                </li>
                <li>
                  <h4>Improve fuel efficiency:</h4>
                  <p>
                    Fuel efficiency is influenced by various factors such as
                    tire pressure, vehicle load, driving habits, and traffic
                    conditions. However, by adopting the right driving practices
                    andmaintaining your vehicle in optimal condition, you can
                    enjoy 10-15% more fuel efficiency than before. With our
                    advanced technology, ensuring your vehicle is in top shape
                    will allow you to get the most out of every drop of fuel,
                    helping you save both time and money.
                  </p>
                </li>
                <li>
                  <h4>Reduce Exhaust Gas Emissions:</h4>
                  <p>
                    With lower fuel consumption comes a direct reduction in
                    carbon emissions. X POWER GOLD SERIES help to meet
                    environmental sustainability goals by lowering particulate
                    matter and greenhouse gases, supporting compliance with
                    stringent environmental protection regulations
                  </p>
                </li>
                <li>
                  <h4>
                    Extended component Lifespan and Reduce Maintenance Costs:
                  </h4>
                  <p>
                    X POWER GOLD SERIEShelps to reduce wear and tear and
                    enhancing the lifespan of critical engine components, less
                    frequent repairs, and a noticeable reduction in engine
                    repair costs. As a result, the need for costly engine spare
                    parts replacements is minimized. By extending the life of
                    these essential components, X POWER GOLD SERIES contribute
                    to improved operational efficiency and long-term cost
                    savings, ensuring your vehicles run smoothly for years to
                    come.
                  </p>
                </li>
                <li>
                  <h4>Protection against Water Contamination in Engine Oil:</h4>
                  <p>
                    With the increasing impact of climate change, unexpected
                    weather patterns, including heavy rainfall in the UAE, have
                    become more frequent. In cases where water enters or mixes
                    with Engine oil, it can cause severe damage to internal
                    components, leading to costly repairs and operational
                    downtime.
                  </p>
                  <p>
                    X POWER GOLD SERIES is engineered to minimize the harmful
                    effects of water contamination by providing an additional
                    layer of protection to critical components. This advanced
                    formulation helps reduce internal wear and allows operations
                    to continue for a certain period, preventing immediate
                    failures and enabling timely maintenance interventions
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="address-ins">
            <h1>
              Please Refer to the owners manual to determine the correct grade
              of engine oil for your vehicle{" "}
            </h1>
            <h3>For sales and technical assistance please contact</h3>
            <h4>Tirrent Global General Trading LLC</h4>
            <p><b>Address:</b> 2403, Al Moosa Tower-2, Sheikh Zaid Road, Opp Emirates Tower – Dubai UAE</p>
            <p><b>Email:</b> support@tirrentglobal.com</p>
            <p><b>Website:</b><a href="https://www.tirrentglobal.com/" target="_blank"> www.tirrentglobal.Com</a></p>
            <p><b>Telephone:</b> +97145579964</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Instruction;
