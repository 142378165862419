import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SiteRoute from "./SiteTemplates/TirrentGlobal/SiteRoute";
import Instruction from "./Instruction";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<SiteRoute />} />
        
        <Route
          path="/instructions"
          element={<Instruction/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
