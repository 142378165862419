import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function Blogs() {
  const { t } = useTranslation(["Blogs"]);

  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-area blog-area-two pt-100 pb-70">
        <div className="container">
          <div className="row ">
          <div className="col-sm-6 col-lg-4" style={{border: "1px solidrgb(124, 11, 11)"}}>
              <div className="blog-item seperate-blog">
                <Link to="/blog/dyno-testing-report">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img15"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/dyno-testing-report">
                      {t("blog.heading15")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/dyno-testing-report"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/sheikha-hend-faisal-al-qasimi-joins-tirrent-global-as-honorary-director">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img14"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/sheikha-hend-faisal-al-qasimi-joins-tirrent-global-as-honorary-director">
                      {t("blog.heading14")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/sheikha-hend-faisal-al-qasimi-joins-tirrent-global-as-honorary-director"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/the-synergy-of-used-oil-analysis-and-lubricity">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img13"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/the-synergy-of-used-oil-analysis-and-lubricity">
                      {t("blog.heading13")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/the-synergy-of-used-oil-analysis-and-lubricity"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/what-is-used-oil-analysis">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img12"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/what-is-used-oil-analysis">
                      {t("blog.heading12")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/what-is-used-oil-analysis"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/lubricating-a-sustainable-and-efficient-future">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img11"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/lubricating-a-sustainable-and-efficient-future">
                      {t("blog.heading11")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/lubricating-a-sustainable-and-efficient-future"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/is-your-vehicle-sipping-more-fuel-than-it-should">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img10"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/is-your-vehicle-sipping-more-fuel-than-it-should">
                      {t("blog.heading10")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/is-your-vehicle-sipping-more-fuel-than-it-should"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/the-role-of-tirrent-global">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img9"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/the-role-of-tirrent-global">
                      {t("blog.heading9")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/the-role-of-tirrent-global"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/the-advantage-of-tirrent-global">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/the-advantage-of-tirrent-global">
                      {t("blog.heading8")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/the-advantage-of-tirrent-global"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/tirrent-global-solutions-for-the-marine-industry">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/tirrent-global-solutions-for-the-marine-industry">
                      {t("blog.heading7")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/tirrent-global-solutions-for-the-marine-industry"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/why-timely-oil-changes-are-crucial">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/why-timely-oil-changes-are-crucial">
                      {t("blog.heading6")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/why-timely-oil-changes-are-crucial"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/boosting-performance-and-protecting-your-engine">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/boosting-performance-and-protecting-your-engine">
                      {t("blog.heading5")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/boosting-performance-and-protecting-your-engine"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog/unleashing-hybrid-engine-enhancing-performance-with-tirrent-booster">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/unleashing-hybrid-engine-enhancing-performance-with-tirrent-booster">
                      {t("blog.heading4")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/unleashing-hybrid-engine-enhancing-performance-with-tirrent-booster"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog/a-revolutionary-lubricating-experience">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/a-revolutionary-lubricating-experience">
                      {t("blog.heading3")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/a-revolutionary-lubricating-experience"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog/solutions-to-common-vehicle-problems">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/solutions-to-common-vehicle-problems">
                      {t("blog.heading2")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/solutions-to-common-vehicle-problems"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog/most-common-automobile-engine-problems-and-solutions">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog/most-common-automobile-engine-problems-and-solutions">
                      {t("blog.heading1")}
                    </Link>
                  </h3>
                  <Link
                    to="/blog/most-common-automobile-engine-problems-and-solutions"
                    className="blog-link"
                  >
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Blogs;
