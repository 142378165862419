import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs15() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog15"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog15.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/banner.jpeg"
                    width="100%"
                  />
                  <h2>{t("blog15.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>
                  <b>{t("blog15.1")}</b> : <b>{t("blog15.1.1")}</b>
                </p>
                <p>
                  <b>{t("blog15.2")}</b> : <b>{t("blog15.2.1")}</b>
                </p>
                <p>
                  <b>{t("blog15.3")}</b> : <b>{t("blog15.3.1")}</b>
                </p>
                <p>
                  <b>{t("blog15.4")}</b> : <b>{t("blog15.4.1")}</b>
                </p>
                <p>
                  <b>{t("blog15.5")}</b> : <b>{t("blog15.5.1")}</b>
                </p>
                <p>
                  <b>{t("blog15.6")}</b> : <b>{t("blog15.6.1")}</b>
                </p>

                <h6 className="mt-5">{t("blog15.one")}</h6>
                <p>{t("blog15.paraone")}</p>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/vechicel.jpg"
                  width="100%"
                />
                <h6 className="mt-5">{t("blog15.two")}</h6>
                <p>{t("blog15.paratwo")}</p>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/graph1.jpg"
                  width="100%"
                />
                <p className="mt-5">{t("blog15.paratwoone")}</p>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/graph2.jpg"
                  width="100%"
                />
                <h6 className="mt-5">{t("blog15.three")}</h6>
                <p>{t("blog15.parathree")}</p>

                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/Emission Test Before_page-0001.jpg"
                      width="100%"
                      className="mt-5"
                      style={{ border: "1px solid #000" }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-15/Emission Test After_page-0001.jpg"
                      width="100%"
                      className="mt-5"
                      style={{ border: "1px solid #000" }}
                    />
                  </div>
                </div>

                <h6 className="mt-5">{t("blog15.four")}</h6>
                <p>{t("blog15.parafour")}</p>

                <table>
                  <tr class="highlight">
                    <th>Test</th>
                    <th>Horsepower (HP)</th>
                    <th>Torque (ft-lbs)</th>
                    <th>Emissions</th>
                    <th>Temperature</th>
                  </tr>
                  <tr>
                    <td>Baseline (Before Application)</td>
                    <td>208.7 HP</td>
                    <td>236.2</td>
                    <td>10.30</td>
                    <td>108°C</td>
                  </tr>
                  <tr>
                    <td>Post-Application (Immediate)</td>
                    <td>214.9 HP</td>
                    <td>248.8</td>
                    <td>—</td>
                    <td>—</td>
                  </tr>
                  <tr>
                    <td>Post-Application (After 500 km)</td>
                    <td>220.6 HP</td>
                    <td>258.3</td>
                    <td>8.70</td>
                    <td>89°C</td>
                  </tr>
                </table>

                <h6 className="mt-5">{t("blog15.five")}</h6>
                <p>{t("blog15.parafive")}</p>

                <h6 className="mt-5">{t("blog15.six")}</h6>
                <p>{t("blog15.parasix")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs15;
