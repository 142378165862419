import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { locationContext } from "../Context/LocationContext";
function ProductXpower() {
  const { t } = useTranslation(["Product"]);
  const { accessToBuyProduct } = useContext(locationContext);
  const [tabs, setTabs] = useState(0);
  const tab = [
    <div class="tab-content" id="tab-1">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product5.directionofuse.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.one")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.two")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.three")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.four")}</p>
                </div>
              </li><li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.five")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.six")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.directionofuse.seven")}</p>
                </div>
              </li>
            </ul>
          </div>
         
        </div>
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product5.usages.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.one")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.two")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.three")}</p>
                </div>
              </li>

              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.four")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.five")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product5.usages.six")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>,

    <div class="tab-content" id="tab-2">
      <div className="right-content">
        <div className="row">
          <div className="col-md-12 key-features-product">
            <h5>
              <span className="product-booster">
                {t("Product5.application.title")}
              </span>
            </h5>
            <div className="applications">
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.one")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.two")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.three")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.four")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.five")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.six")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.seven")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      backgroundColor: "#f99f36",
                      padding: "1px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  ></i>{" "}
                  <div>
                    <p>{t("Product5.application.eight")}</p>
                  </div>
                </li>
              </ul>
            </div>
           

           
          </div>
          
        </div>
      </div>
    </div>,
  ];
  const navigate = useNavigate();
  const clickTab = (e) => {
    setTabs(e.target.id);
  };

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2> {t("Product5.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
            <div className="row mt-5">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="container-product"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/x-power.png"
                    alt="img"
                    width="100%"
                  />
                </div>
                {/* {accessToBuyProduct && (
                  <div className="container-product mt-5">
                    <div className="box__link button-animation">
                      <a
                        href="https://www.flipkart.com/tirrent-global-tg0001tb-booster-engine-treatment-high-performance-oil/p/itm52789ccd70f75?pid=VLUGWNHJSUKEEYV9&lid=LSTVLUGWNHJSUKEEYV9NPZJDO&marketplace=FLIPKART&q=tirrent+global&store=1mt%2F0mi&srno=s_1_1&otracker=AS_QueryStore_OrganicAutoSuggest_1_8_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_8_na_na_ps&fm=search-autosuggest&iid=509ea9d6-ad89-465f-a092-5e7d8e81929a.VLUGWNHJSUKEEYV9.SEARCH&ppt=sp&ppn=sp&ssid=w50zxi82og0000001734007004619&qH=c582a6a15d49a6dd"
                        target="_blank"
                      >
                        {t("Product1.button.buynow")}
                      </a>

                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-lg-7 tirrent-booster">
                <h2>
                  <span className="product-booster"> {t("Product5.title")}</span>
                </h2>
                <h2> {t("Product5.span")}</h2>
                <p>
                {t("Product5.description")}
                </p>
                <p>
                {t("Product5.description2")}
                </p>
                <div className="row">
                  <div className="col-md-8">
                    <div className="right-content keyfeatures">
                   <ul>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product5.keyfeatures.one")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.two")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.three")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.four")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.five")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.six")} </p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                          <p>{t("Product5.keyfeatures.seven")} </p>
                          </div>
                        </li>
                      </ul> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-container">
              <div class="tabs">
                <div class="tab-links">
                  <button
                    class={tabs == "0" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="0"
                  >
                    <i class="fas fa-info-circle"></i>
                    {t("Product.usage")}
                  </button>
                  <button
                    class={tabs == "1" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="1"
                  >
                    <i class="fas fa-list"></i> {t("Product.applications")}
                  </button>
                </div>
                {tab[tabs]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductXpower;
