import React, { useEffect, useState } from "react";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Modal from "../Shared/Modal";
function Contact() {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation(["Contact"]);
  const Toggle = () => setToggle((prv) => !prv);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  const [currentImageIndex, setCurrentImageIndex] = useState();

  // dataImg[currentCountry].map((data) => {
  //   items.push(
  //     <div className="" style={{ display: "flex", justifyContent: "center" }}>
  //       <img src={data} width="75%" />
  //     </div>
  //   );
  // });

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2> {t("contact.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-area ptb-100">
        <div className="container">
          <div className="row contact-bg">
            <div className="col-lg-7">
              <div className="contact-item contact-left">
                <img
                  src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                    "1.leftImg"
                  )}.png`}
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="connect">
                <div style={{ marginBottom: "30px" }}>
                  <h1>{t("1.getintouch")}</h1>
                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>

                  <span>
                    <i
                      className="fa fa-envelope"
                      style={{ color: "#f4a340" }}
                    ></i>
                  </span><a href="mailto:support@tirrentglobal.com">
                    <h6>support@tirrentglobal.com</h6>
                  </a>

                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>
                  <span>
                    <i
                      className="fa fa-house-chimney"
                      style={{ color: "#f4a340" }}
                    ></i>
                  </span>
                  <h6 className="mb-0">{t("1.usaaddress")}</h6>
                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>
                  <span>
                    <i className="fa fa-phone" style={{ color: "#f4a340" }}></i>
                  </span>
                  <h6>+971 4 557 9964</h6>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="offer-slider owl-theme maps mt-5">
          <div className="row m-0" >
            <div className="col-md-12">
             
                <div className="offer-top">
                  <div className="map-area">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d917.6796924211071!2d55.2774225!3d25.2160864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430026eaba1f%3A0x9274f4bf16efb71f!2sTirrent%20Global!5e1!3m2!1sen!2sin!4v1737543241110!5m2!1sen!2sin" width="100%" height="600" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  {/* <i className="flaticon-3d-print"></i> */}
                </div>
              
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 p-0">
              <img src="/SiteTemplate/TirrentGlobal/assets/img/warehouse.jpg" width="100%" alt="ware-house" />
            </div>
            <div className="col-md-6 distributor p-0">
              <div className="dist">
                <h1>{t("dist.title")}</h1>
                <p>{t("dist.para")}</p>
                <button
                  onClick={(e) => {
                    Toggle(e);
                    setCurrentImageIndex();
                  }}
                  className="cmn-btn distrubutor-btn"> {t("dist.btn")}</button>
              </div>
            </div></div>
        </div>

      </section>

      {toggle && (
        <Modal show={toggle} close={Toggle}>
          <div className="contact-form">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="col-md-10"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div className="contact-item contact-right pt-5 pb-5">
                        <h3 style={{ color: "#000" }}>{t("1.getintouch")}</h3>
                        <form id="contactForm">
                          <div className="row">
                            <div className="col-sm-6 col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="form-control"
                                  required
                                  data-error="Please enter your name"
                                  placeholder={t("1.name")}
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  required
                                  data-error="Please enter your email"
                                  placeholder={t("1.email")}
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="phone_number"
                                  id="phone_number"
                                  required
                                  data-error="Please enter your number"
                                  className="form-control"
                                  placeholder={t("1.phone")}
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="msg_subject"
                                  id="msg_subject"
                                  className="form-control"
                                  required
                                  data-error="Please enter your subject"
                                  placeholder={t("1.subject")}
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-12">
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  className="form-control"
                                  id="message"
                                  cols="30"
                                  rows="8"
                                  required
                                  data-error="Write your message"
                                  placeholder={t("1.msg")}
                                ></textarea>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-12">
                              <button type="submit" className="contact-btn btn cmn-btn">
                                {t("1.bntmsg")}
                              </button>
                              <div
                                id="msgSubmit"
                                className="h3 text-center hidden"
                              ></div>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Footer />
    </>
  );
}

export default Contact;
